import getPrefix from './getPrefix.mjs';

const getBreadcrumbsJsonLdData = (
  url,
  categoryPathName,
  categoryTitle,
  title,
) => {
  const absoluteUrl = `https://${getPrefix(url)}/`;
  const categoryPath = `${categoryPathName.toLowerCase()
  }/${categoryTitle.toLowerCase()}`;
  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement:
      [
        {
          '@type': 'ListItem',
          position: 1,
          item:
        {
          '@id': absoluteUrl,
          name: 'Home',
        },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item:
        {
          '@id': `${absoluteUrl}${categoryPath}`,
          name: categoryTitle,
        },
        },
        {
          '@type': 'ListItem',
          position: 3,
          item:
        {
          '@id': url,
          name: title,
        },
        },
      ],
  });
};

export default getBreadcrumbsJsonLdData;
