const splitImageUrls = (originalImageUrls?: string[]) => {
  const [metaImageUrl, ...imageUrls] = originalImageUrls ?? [];

  return {
    metaImageUrl,
    imageUrls,
  };
};

export default splitImageUrls;
