import React from 'react';
import { Breadcrumbs } from '@fuww/library/src/Breadcrumb';
import PropTypes from 'prop-types';
import capitalize from 'lodash/capitalize';
import { useIntl } from 'react-intl';
import LinkAndAnchor from '../../LinkAndAnchor';
import HomeLink from '../../HomeLink';
import getCategoryParameters from '../../../lib/getCategoryParameters';
import getArticlesParameters from '../../../lib/getArticlesParameters';
import getBreadcrumbsJsonLdData from '../../../lib/getBreadcrumbsJsonLdData';
import getCategoryPath from '../../../lib/getCategoryPath';
import getCategoryBreadcrumb from '../../../lib/getCategoryBreadcrumb';
import getCategoryTitle from '../../../lib/getCategoryTitle';
import { useSiteContext } from '../../SiteContext';

const ArticleBreadCrumbs = ({
  article,
}) => {
  const intl = useIntl();
  const { hasLocalNews, url, locale } = useSiteContext();

  const { category, title, path } = article;

  if (!category || category.path === 'uncategorised') {
    return (
      <Breadcrumbs
        crumbs={[
          <HomeLink />,
          title,
        ]}
      />
    );
  }

  const categoryTitle = capitalize(
    getCategoryTitle(category, hasLocalNews, intl),
  );

  return (
    <>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: getBreadcrumbsJsonLdData(
            url,
            getCategoryPath(category, hasLocalNews, intl),
            categoryTitle,
            title,
          ),
        }}
      />
      <Breadcrumbs
        crumbs={
          [
            <HomeLink />,
            getCategoryBreadcrumb(category, hasLocalNews, intl, locale),
            <LinkAndAnchor
              route="category"
              params={getCategoryParameters(category)}
            >
              {categoryTitle}
            </LinkAndAnchor>,
            <LinkAndAnchor
              route="article"
              params={getArticlesParameters(article)}
              href={path}
              useRouter
              passHref
            >
              {title}
            </LinkAndAnchor>,
          ]
        }
      />
    </>
  );
};

ArticleBreadCrumbs.propTypes = {
  article: PropTypes.shape({
    category: PropTypes.shape({
      path: PropTypes.string,
    }),
    title: PropTypes.string,
    path: PropTypes.string,
  }),
};

ArticleBreadCrumbs.defaultProps = {
  article: {},
};

export default ArticleBreadCrumbs;
