const shareList = ({
  title,
  url,
  media,
  locale,
  page,
  fill = 'white',
  logoFillColor = 'grey',
} : {
  title?: string;
  url: string;
  media?: string;
  locale: string;
  page: string;
  fill?: string;
  logoFillColor?: string;
}) : {
  name: string;
  modalProps: {
    title?: string;
    url: string;
    media?: string;
    quote?: string;
  };
  iconProps: {
    iconBgStyle: { fill: string };
    logoFillColor: string;
    size?: number;
  };
  additionalProps: {
    'aria-label': string;
  };
  className: string;
  locales?: string[];
  pages?: string[];
  checkMedia?: boolean;
}[] => [
  {
    name: 'Email',
    modalProps: {
      title,
      url,
    },
    iconProps: {
      iconBgStyle: { fill },
      logoFillColor,
    },
    additionalProps: {
      'aria-label': 'Share via email Button',
    },
    className: 'email-share',
    pages: ['job'],
  },
  {
    name: 'Twitter',
    modalProps: {
      title,
      url,
    },
    iconProps: {
      iconBgStyle: { fill },
      logoFillColor,
    },
    additionalProps: {
      'aria-label': 'Twitter Button',
    },
    className: 'twitter-share',
  },
  {
    name: 'Facebook',
    modalProps: {
      quote: title,
      url,
    },
    iconProps: {
      iconBgStyle: { fill },
      logoFillColor,
    },
    additionalProps: {
      'aria-label': 'Facebook Button',
    },
    className: 'facebook-share',
  },
  {
    name: 'Linkedin',
    modalProps: {
      title,
      url,
    },
    iconProps: {
      iconBgStyle: { fill },
      logoFillColor,
    },
    additionalProps: {
      'aria-label': 'Linkedin Button',
    },
    className: 'linkedIn-share',
  },
  {
    name: 'Pinterest',
    checkMedia: true,
    modalProps: {
      title,
      url,
      media,
    },
    iconProps: {
      iconBgStyle: { fill },
      logoFillColor,
    },
    additionalProps: {
      'aria-label': 'Pinterest Button',
    },
    className: 'pinterest-share',
  },
  {
    name: 'Whatsapp',
    modalProps: {
      quote: title,
      url,
    },
    iconProps: {
      iconBgStyle: { fill },
      logoFillColor,
      size: 27,
    },
    additionalProps: {
      'aria-label': 'Whatsapp Button',
    },
    className: 'whatsapp-share',
  },
  {
    name: 'VK',
    checkMedia: true,
    modalProps: {
      title,
      url,
      media,
    },
    iconProps: {
      iconBgStyle: { fill },
      logoFillColor,
    },
    additionalProps: {
      'aria-label': 'VK Button',
    },
    className: 'vk-share',
    locales: ['ru-RU'],
  },
].filter(
  (share) => !share.locales || share.locales.includes(locale),
).filter(
  (share) => !share.pages || share.pages.includes(page),
);

export default shareList;
