import {
  DEFAULT_ARTICLE_META_IMAGE, metaImageDimensions,
} from './constants';

const getArticleJsonLdData = (article, url, origin) => {
  const {
    imageUrls, title, createdAt, updatedAt, description,
  } = article;
  const metaImageUrl = imageUrls[0];
  const absoluteImageUrl = `${
    origin}${metaImageUrl || DEFAULT_ARTICLE_META_IMAGE}`;
  const creator = { ...article.creator };

  return JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'NewsArticle',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: title,
    image: {
      '@type': 'ImageObject',
      url: absoluteImageUrl,
      width: metaImageDimensions.width,
      height: metaImageDimensions.height,
    },
    datePublished: createdAt,
    dateModified: updatedAt,
    author: {
      '@type': 'Person',
      name: creator.name,
    },
    publisher: {
      '@type': 'Organization',
      name: 'FashionUnited',
      logo: {
        '@type': 'ImageObject',
        url: 'https://media.fashionunited.com/media/logo/logo-600x51.png',
        width: 600,
        height: 51,
      },
    },
    description,
  });
};

export default getArticleJsonLdData;
