import upperFirst from 'lodash/upperFirst';
import getArticleField from './queries/news/getArticleField';

const getArticleSubjectType = (
  prefix: string,
  hasLocalNews: boolean,
) => upperFirst(
  getArticleField(prefix, hasLocalNews, false),
);

export default getArticleSubjectType;
